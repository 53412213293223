import { IncidentArea } from './incident-area';
import { Beneficiary } from './beneficiary';
import { IncidentReason } from './incident-reason';
import { Prescription } from './prescription';
import { User } from './user';

export enum IncidentStatus {
  'New' = 'New',
  'Open' = 'Open',
  'Pending' = 'Pending',
  'Resolved' = 'Resolved',
}

export enum IncidentType {
  'Question' = 'Question',
  'Incident' = 'Incident',
  'Problem' = 'Problem',
  'Task' = 'Task',
}

export enum IncidentPriority {
  'Low' = 'Low',
  'Normal' = 'Normal',
  'High' = 'High',
  'Urgent' = 'Urgent',
}

export enum IncidentApplicantType {
  'User' = 'User',
  'Financier' = 'Financier',
  'Pharmacy' = 'Pharmacy',
  'Doctor' = 'Doctor',
  'Beneficiary' = 'Beneficiary',
}

export enum IncidentOrigin {
  'App' = 'App',
  'Web' = 'Web',
}

export interface IncidentApplicant {
  id?: number;
  entityDisplay?: string;
}

export interface Incident {
  id?: number;
  status?: IncidentStatus;
  applicantType?: IncidentApplicantType;
  applicantId?: number;
  applicantDisplay?: string;
  userId?: number;
  incidentAreaId?: number;
  prescriptionId?: number;
  incidentReasonId?: number;
  type?: IncidentType;
  priority?: IncidentPriority;
  subject?: string;
  description?: string;
  applicant?: IncidentApplicant;
  beneficiaryId?: number;
  beneficiary?: Beneficiary;
  prescription?: Prescription;
  user?: User;
  incidentArea?: IncidentArea;
  incidentReason?: IncidentReason;
  createdAt?: Prescription;
  origin?: IncidentOrigin;
  formDate?: Date;
  prescriptionDate?: Date;
  messageBeneficiary?: string;
}
