export const environment = {
  production: true,
  apiUrl: 'https://it-farma-cloud-dev.uk.r.appspot.com',
  firebaseConfig: {
    apiKey: "AIzaSyAl77QxqtoZND-2ww1wfFNfYNFl_0VOCqE",
    authDomain: "it-farma-cloud-dev.firebaseapp.com",
    databaseURL: "https://it-farma-cloud-dev.firebaseio.com",
    projectId: "it-farma-cloud-dev",
    storageBucket: "it-farma-cloud-dev.appspot.com",
    messagingSenderId: "916736683161",
    appId: "1:916736683161:web:a57cf27e7cc4da585a5c08",
    measurementId: "G-PS3RTEQK6C"
  }
};
