import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { getHttpParams, processApiList } from '..';
import { ApiList, Locality, QueryParams } from '../models';
import { ResourceEntityApiService } from './resource-entity-api-service';

@Injectable()
export class LocalityApiService extends ResourceEntityApiService<Locality> {
  resourceUrl = 'localities';

  constructor(protected http: HttpClient) {
    super(http);
  }

  getLocalitiesByRegionName(
    regionName: string,
    queryParams?: QueryParams
  ): Observable<ApiList<Locality>> {
    return this.http
      .get<ApiList<Locality>>(
        `${environment.apiUrl}/localities/regionName/${regionName}`,
        {params: getHttpParams(queryParams),}
      )
      .pipe(
        tap(processApiList),
        map((list) => this.parseList(list))
      );
  }
}
