import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Company} from '../models';
import { ResourceEntityApiService } from './resource-entity-api-service';

@Injectable()
export class CompanyApiService extends ResourceEntityApiService<Company> {
  resourceUrl = 'companies';

  constructor(protected http: HttpClient) {
    super(http);
  }
}
