import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CancelEntity, Dispatch, EntityFile } from '../models';
import { parseUtcDateProperties } from '../utils';
import { EntityFileApiHelperService } from './entity-file-api-helper.service';
import { ResourceEntityApiService } from './resource-entity-api-service';
import { environment } from '@environment';
import { CommonMessageService } from '@app/shared/common';
import { TranslateService } from '@ngx-translate/core';
import { Translations } from '@app/core';

@Injectable()
export class DispatchApiService extends ResourceEntityApiService<Dispatch> {
  resourceUrl = 'dispatches';

  constructor(
    protected http: HttpClient,
    protected entityFileApiHelperService: EntityFileApiHelperService,
    private messageService: CommonMessageService,
    private translate: TranslateService,
    private readonly lang: Translations
  ) {
    super(http);
  }

  cancel(data) {
    return this.http.post<CancelEntity>(
      `${this.getItemUrl(data)}/cancel`,
      data
    );
  }

  parseEntity(entity) {
    parseUtcDateProperties(entity, ['date', 'prescriptionDate', 'documentsReceptionDate']);
    entity.beneficiary = entity.prescription?.beneficiary; // TODO: remove
    entity.beneficiaryId = entity.prescription?.beneficiaryId; // TODO: remove
    return entity;
  }

  getAllFiles(entityParams, queryParams?) {
    return this.entityFileApiHelperService.getAllFiles(
      this,
      entityParams,
      queryParams
    );
  }

  getFileById(entityParams, fileId) {
    return this.entityFileApiHelperService.getFileById(
      this,
      entityParams,
      fileId
    );
  }

  uploadFile(entityParams, files) {
    return this.entityFileApiHelperService.uploadFile(
      this,
      entityParams,
      files
    );
  }

  deleteFile(entityParams, fileId) {
    return this.entityFileApiHelperService.deleteFile(
      this,
      entityParams,
      fileId
    );
  }

  downloadFile(file: EntityFile) {
    this.entityFileApiHelperService.downloadFile(this, file);
  }

  audit(data) {
    return this.http.post<any>(
      `${environment.apiUrl}/dispatches/${data.id}/audit`,
      data
    );
  }

  createDispatch(data) {
    return this.http.post<any>(`${environment.apiUrl}/dispatches`, data);
  }

  unduAuditDispatch(data) {
    return this.http.put<any>(
      `${environment.apiUrl}/dispatches/${data.id}/unduAudit`,
      data
    );
  }
}
