import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Translations } from '@app/core';
import { FileUpload } from 'primeng/fileupload';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploadComponent),
      multi: true
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileUploadComponent {
  @ViewChild('fileUpload') fileUpload: FileUpload;

  @Output() uploadHandler = new EventEmitter<any>();

  @Input() label: string;
  @Input() auto: boolean;

  uploading: boolean;

  constructor(private cdRef: ChangeDetectorRef,
              public readonly lang: Translations) {}

  upload(event) {
    this.uploading = true;
    this.uploadHandler.emit(event);
  }

  clear() {
    this.uploading = false;
    this.fileUpload.clear();
    this.fileUpload.onBlur();
    this.cdRef.markForCheck();
  }
}
